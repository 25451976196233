import React from 'react';
import './App.css';  // Import global styles
import Header from './components/header';
import Hero from './components/hero';

function App() {
  return (
      <div className="App">
        <Header />
        <Hero />
      </div>
  );
}

export default App;
